<template>
  <router-link :to="`/discover/${$route.params.state}/associated/${region.elements.slug.value}`" class="associated-region-tile">
    <div class="pin" v-if="showPin">
      <img src="@/assets/pin.png" alt="" width="25" height="25">
<!--      <svg id="Group_3_Copy" data-name="Group 3 Copy" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="25" viewBox="0 0 20 25">-->
<!--        <defs>-->
<!--          <clipPath id="clip-path">-->
<!--            <path id="Clip_2" data-name="Clip 2" d="M0,0H20V25H0Z" fill="none"/>-->
<!--          </clipPath>-->
<!--        </defs>-->
<!--        <path id="Clip_2-2" data-name="Clip 2" d="M0,0H20V25H0Z" fill="none"/>-->
<!--        <g id="Group_3_Copy-2" data-name="Group 3 Copy" clip-path="url(#clip-path)">-->
<!--          <path id="Fill_1" data-name="Fill 1" d="M10,25a1.41,1.41,0,0,1-1.117-.54l-6.565-8.32A9.768,9.768,0,0,1,0,9.839,9.932,9.932,0,0,1,10,0,9.932,9.932,0,0,1,20,9.839a9.745,9.745,0,0,1-2.463,6.467l-6.42,8.154A1.41,1.41,0,0,1,10,25Zm0-10.1A1.826,1.826,0,1,0,11.86,16.73,1.843,1.843,0,0,0,10,14.9Zm2.413-4.231A1.826,1.826,0,1,0,14.273,12.5,1.843,1.843,0,0,0,12.417,10.674Zm-4.835,0A1.826,1.826,0,1,0,9.438,12.5,1.843,1.843,0,0,0,7.582,10.674Zm7.257-4.231a1.826,1.826,0,1,0,1.856,1.826A1.843,1.843,0,0,0,14.839,6.443ZM10,6.443A1.826,1.826,0,1,0,11.86,8.269,1.843,1.843,0,0,0,10,6.443Zm-4.844,0A1.826,1.826,0,1,0,7.016,8.269,1.843,1.843,0,0,0,5.161,6.443Z" fill="#E8490F"/>-->
<!--        </g>-->
<!--      </svg>-->
    </div>
    <div class="image">
      <img v-if="region.elements.hero_image && region.elements.hero_image.value.length" :src="`${region.elements.hero_image.value[0].url}?w=440&h=240&fit=crop`">
    </div>
    <div class="title">
      {{region.elements.name.value}}
      <svg id="Arrows_Copy" data-name="Arrows Copy" xmlns="http://www.w3.org/2000/svg" width="67" height="43" viewBox="0 0 67 43">
        <rect id="Rectangle_Copy_6" data-name="Rectangle Copy 6" width="67" height="43"/>
        <g id="Action_Right_Arrow" data-name="Action_Right Arrow" transform="translate(18 14)">
          <g id="Group_3" data-name="Group 3" transform="translate(0 0.167)">
            <rect id="Rectangle" width="30.189" height="1.231" transform="translate(0 7.217)" fill="#d8d8d8"/>
            <g id="Group" transform="translate(16.466 7.833) rotate(-45)">
              <rect id="Rectangle_Copy" data-name="Rectangle Copy" width="10.972" height="1.219" transform="translate(-0.093 9.753)" fill="#d8d8d8"/>
              <rect id="Rectangle_Copy_2" data-name="Rectangle Copy 2" width="10.972" height="1.219" transform="translate(9.649 10.88) rotate(-90)" fill="#d8d8d8"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'AssociatedRegionTile',
  props: {
    region: {
      type: Object,
      default () {
        return null
      }
    },
    showPin: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.associated-region-tile {
  position: relative;
  padding-right: 20px;
  padding-top: 12px;
  display: block;
  font-size: 14px;
  font-weight: bold;
}

.pin {
  position: absolute;
  right: 25px;
  top: -2px;
  z-index: 2;
}
.image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 54.54%;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}
.title {
  position: relative;
  line-height: 1.2;
  padding: 15px 50px 15px 0;

  svg {
    position: absolute;
    right: -17px;
    top: -20px;
    z-index: 3;
  }
}
</style>
