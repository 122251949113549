<template>
  <div class="state">
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-20 md:top-40 lg:top-60 w-full bottom-0 z-100 bg-black transition-all duration-500" :class="[{'invisible opacity-0': state}]">
      <loading-spinner />
    </div>
    <div class="page min-h-screen">
      <div v-if="state">
        <div class="outer-container">
          <div class="inner-container">
            <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
              <router-link to="/discover" class="hover:text-white transition-colors">Discover</router-link><span class="divider inline-block px-4">&gt;</span><span class="text-white">{{ state.elements.page__page_title.value }}</span>
            </div>
            <div class="map mt-16">
              <div class="state-regions">
                <div class="region-list">
                  <div class="state">
                    <img src="@/assets/our-places.png" alt="">
                    <h1>{{ state.elements.page__page_title.value }}<span>{{ state.elements.abbreviation.value }}</span></h1>
                    <div class="heading-border"></div>
                  </div>

                  <p class="mt-12">{{ state.elements.intro_text.value }}</p>

<!--                  <div class="alpaca-link" v-if="state.elements.alpaca_state.value">-->
<!--                    <a href="#" v-if="!$auth.isAuthenticated" @click.prevent="showPopup" class="flex flex-row items-center">-->
<!--                      <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">-->
<!--                        <path d="M9.875 5.75H9.3125V4.0625C9.3125 2.11719 7.69531 0.5 5.75 0.5C3.78125 0.5 2.1875 2.11719 2.1875 4.0625V5.75H1.625C0.992188 5.75 0.5 6.26562 0.5 6.875V11.375C0.5 12.0078 0.992188 12.5 1.625 12.5H9.875C10.4844 12.5 11 12.0078 11 11.375V6.875C11 6.26562 10.4844 5.75 9.875 5.75ZM7.4375 5.75H4.0625V4.0625C4.0625 3.14844 4.8125 2.375 5.75 2.375C6.66406 2.375 7.4375 3.14844 7.4375 4.0625V5.75Z" fill="#E8490F"/>-->
<!--                      </svg>-->
<!--                      View this area on our interactive map &gt;-->
<!--                    </a>-->
<!--                    <a v-else :href="`/explore/map?state=${state.elements.alpaca_state.value}`">View this area on our interactive map &gt;</a>-->
<!--                  </div>-->
                  <div class="links" v-if="regions">
                    <h5>Regions of {{state.elements.abbreviation.value}}</h5>
                    <ul>
                      <li v-for="region in regions" :key="`region_${region.system.id}`"><router-link :to="`/discover/${ state.elements.slug.value }/${ region.elements.slug.value }`">{{ region.elements.page__h1_title.value.length ? region.elements.page__h1_title.value : region.elements.page__page_title.value }}</router-link></li>
                    </ul>
                  </div>
                </div>
                <div class="map" v-if="state.elements.connect_map.value.length" :key="state.system.codename + 'map'">
                  <img :src="state.elements.connect_map.value[0].url"/>
                </div>
              </div>
            </div>


            <div class="associated-regions" v-if="associatedRegions.length">
              <h5>Regional Associations</h5>
              <div class="list">
                <associated-region-tile v-for="(region, index) in associatedRegions" :key="`region-${index}`" :region="region" />
              </div>
            </div>

          </div>

<!--          <locked-content :heading="`${ state.elements.abbreviation.value } Wineries —`" type="wineries" v-if="!$auth.isAuthenticated" />-->
<!--          <locked-content :heading="`${ state.elements.abbreviation.value } Wines —`" type="wines" v-if="!$auth.isAuthenticated" />-->

          <other-wineries-carousel v-if="this.wineries.length" class="mt-28" :view-all-url="`/expo?${$store.state.winerySort}%5BrefinementList%5D%5Bstate%5D%5B0%5D=${state.elements.page__page_title.value.toLowerCase() === 'new south wales' ? 'NSW%20%26%20ACT' : state.elements.page__page_title.value}&${$store.state.productSort}%5BrefinementList%5D%5Bstate%5D%5B0%5D=${state.elements.page__page_title.value.toLowerCase() === 'new south wales' ? 'NSW%20%26%20ACT' : state.elements.page__page_title.value}&scroll=true`" :heading="`${ state.elements.abbreviation.value } Wineries —`" :wineries="this.wineries" />
          <other-wines-carousel v-if="this.products.length" class="mt-28" :view-all-url="`/expo?${$store.state.productSort}%5BrefinementList%5D%5Bstate%5D%5B0%5D=${state.elements.page__page_title.value.toLowerCase() === 'new south wales' ? 'NSW%20%26%20ACT' : state.elements.page__page_title.value}&${$store.state.winerySort}%5BrefinementList%5D%5Bstate%5D%5B0%5D=${state.elements.page__page_title.value.toLowerCase() === 'new south wales' ? 'NSW%20%26%20ACT' : state.elements.page__page_title.value}&scroll=true`" :heading="`${ state.elements.abbreviation.value } Wines —`" :products="this.products" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import OtherWinesCarousel from '../components/OtherWinesCarousel'
import Consumer from "@/kontent/consumer";
import LoadingSpinner from "@/components/LoadingSpinner";
import Kontent from "@/kontent";
import OtherWineriesCarousel from "@/components/OtherWineriesCarousel";
import AssociatedRegionTile from "@/components/AssociatedRegionTile";
import _ from 'lodash'
// import LockedContent from "@/components/LockedContent";
import Bus from '@/events/EventBus';
export default {
  name: 'DiscoverState',
  metaInfo () {
    if (!this.state) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.state.elements.page__meta_title.value.length ? this.state.elements.page__meta_title.value : this.state.elements.page__page_title.value},
      {property: 'og:description', content: this.state.elements.page__page_description.value},
      {name: 'title', content: this.state.elements.page__meta_title.value.length ? this.state.elements.page__meta_title.value : this.state.elements.page__page_title.value},
      {name: 'keywords', content: this.state.elements.page__meta_keywords.value},
      {name: 'description', content: this.state.elements.page__meta_description.value.length ? this.state.elements.page__meta_description.value : this.state.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.state.elements.page__meta_title.value.length ? this.state.elements.page__meta_title.value : this.state.elements.page__page_title.value},
      {name: 'twitter:description', content: this.state.elements.page__meta_description.value.length ? this.state.elements.page__meta_description.value : this.state.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.state.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.state.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.state.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.state.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.state.elements.page__page_type.value[0].name})
    }

    return {
      title: this.state.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {AssociatedRegionTile, OtherWineriesCarousel, LoadingSpinner, OtherWinesCarousel},
  props: {},
  data () {
    return {
      state: null,
      regions: null,
      products: [],
      wineries: [],
      associatedRegions: []
    }
  },
  methods: {
    showPopup() {
      Bus.$emit('show-register-popup')
    }
  },
  mounted () {
    Consumer.getItem('state', this.$route.params.state, 1).then((res) => {
      this.state = res.data.items[0]
      let regions = []
      for (let i = 0; i < this.state.elements.child_items.value.length; i++) {
        regions.push(res.data.modular_content[this.state.elements.child_items.value[i]])
      }
      regions = _.orderBy(regions, ['system.name'], ['asc'])
      this.regions = regions

      Analytics.trackPage('Discover State: ' + this.state.elements.page__page_title.value)
      if (this.$auth.isAuthenticated) {
        this.$store.dispatch("getFavouriteProducts")
        this.$store.dispatch("getFavouriteWineries")
      }

      Kontent.getProductsByState(this.state.elements.page__page_title.value).then((res) => {
        this.products = res.data.items
      })

      Kontent.getWineriesByState(this.state.elements.page__page_title.value).then((res) => {
        this.wineries = res.data.items
      })

      Kontent.getAssociatedRegions(this.state.elements.page__page_title.value).then((res) => {
        this.associatedRegions = res.data.items
      })

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })
  }
}
</script>

<style scoped lang="scss">
h5 {
  margin-top: 30px;
  border-bottom: 1px solid #959595;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.alpaca-link {
  margin-top: 20px;
}

.associated-regions {
  margin-bottom: 50px;

  .list {
    margin-right: -20px;
    @media(min-width: 768px)
    {
      box: horizontal wrap;

      .associated-region-tile {
        width: 50%;
      }
    }

    @media (min-width: 1024px) {
      .associated-region-tile {
        width: 33.3333%;
      }
    }
  }
}

  .state-regions
  {
    @media(min-width: 768px)
    {
      display:flex;
      flex-direction:row;
    }

    .map
    {
      width:100%;
      margin-top:20px;

      @media(min-width: 768px)
      {
        margin-top:0;
        padding-left:20px;
      }

      @media(min-width: 1024px)
      {
        padding-left:40px;
      }

      img
      {
        display:block;
        width:100%;
      }
    }

    .region-list
    {
      flex-shrink: 0;

      @media(min-width: 768px)
      {
        width:350px;
      }

      @media(min-width: 1024px)
      {
        width:500px;
      }

      p
      {
        color:#959595;
      }

      .links
      {
        margin-top:30px;

        ul
        {
          display:flex;
          flex-direction:row;
          flex-wrap:wrap;


          li
          {
            width:50%;
            margin-bottom:20px;

            a
            {
              text-decoration: underline;
              transition: color 0.3s ease;
              &:hover {
                color: #E8490F;
              }
            }
          }
        }
      }

      .state {
        position: relative;

        h1
        {
          line-height: 1;
          text-transform: uppercase;
          position: relative;
          padding-top:10px;
          padding-bottom:18px;
          padding-right:35px;
          font-size: 34px;
          font-weight: bold;
          @media(min-width: 768px)
          {
            font-size: 58px;
          }

          span
          {
            text-transform: uppercase;
            position: absolute;
            bottom:20px;
            right:0;
            font-size: 12px;
          }
        }

        .heading-border
        {
          height:1px;
          background-color: #959595;
          width:100%;
          display: block;
          position: absolute;
          bottom: 0.3rem;
          left:0;
        }

        .design
        {
          width:140px;
          height:40px;
          background-color: #E8490F;
        }
      }
    }

  }
</style>
